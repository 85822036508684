import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@happy-rabbit/component-library";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

const MoreInfoLink = props => {
  const { pageRefs } = useCMSContext();
  const { productDescriptionsAndCareRef } = pageRefs.current || {};

  const handleClick = useCallback(() => {
    productDescriptionsAndCareRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [productDescriptionsAndCareRef]);

  return (
    <div className="mb-6">
      <Button
        className="underline focus:outline-none"
        theme="none"
        onClick={handleClick}
      >
        <FormattedMessage
          id="productView.more-info"
          description="More info link"
          defaultMessage="More info"
        />
      </Button>
    </div>
  );
};

export default MoreInfoLink;
