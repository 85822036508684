import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import { useCountryStateContext } from "../../contexts/CountryContext";
import { urlFor } from "../../utils/sanity";
import SEO from "../SEO";

const AVAILABILITIES = {
  'in-stock': 'InStock',
  'low-stock': 'LimitedAvailability',
  'out-of-stock': 'OutOfStock',
  'incoming-stock': 'PreOrder',
};

const ProductSEO = props => {
  const { product, productColorway, possibleProductVariants, image } = props;
  const { description, name } = product || {};

  const { lang, path, siteMetadata } = useCMSContext();
  const { siteUrl } = siteMetadata;
  const { currencyCode } = useCountryStateContext();

  const productName = [product?.name, getLocaleValue(product?.description, lang), getLocaleValue(productColorway?.name, lang)].filter(Boolean).join(' ');
  const productTitle = name ? {
    en: `Buy ${[name, description?.en, productColorway?.name?.en].filter(Boolean).join(' ')}`,
    sv: `${[name, description?.sv, productColorway?.name?.sv].filter(Boolean).join(' ')}`,
  } : null;

  const validUntil = useMemo(() => {
    let validUntil = new Date();
    validUntil.setMonth(validUntil.getMonth() + 1);
    return validUntil.toISOString().substring(0,10);
  }, []);

  return (
    <>
      <SEO
        pageFields={{
          description: product.summary,
          image: image,
          title: productTitle,
        }}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": productName,
            "image": image ? [
              urlFor(image).width(1280).height(parseInt(1280 / (16/9))).url(),
              urlFor(image).width(1280).height(parseInt(1280 / (4/3))).url(),
              urlFor(image).width(1280).height(1280).url(),
            ] : [],
            "description": getLocaleValue(product.summary, lang),
            "brand": {
              "@type": "Brand",
              "name": "DePalma Workwear"
            },
            "mainEntityOfPage": {
              '@type': 'WebSite',
              '@id': `${siteUrl}${path}`,
            },
            // "review": {
            //   "@type": "Review",
            //   "reviewRating": {
            //     "@type": "Rating",
            //     "ratingValue": "4",
            //     "bestRating": "5"
            //   },
            //   "author": {
            //     "@type": "Person",
            //     "name": "Fred Benson"
            //   }
            // },
            // "aggregateRating": {
            //   "@type": "AggregateRating",
            //   "ratingValue": "4.4",
            //   "reviewCount": "89"
            // },
            "offers": possibleProductVariants.map(productVariant => {
              const price = (productVariant?.price || {})[currencyCode?.toLowerCase()];
              return {
                "@type": "Offer",
                "sku": productVariant.sku,
                "price": price?.amount,
                "priceCurrency": currencyCode,
                "priceValidUntil": validUntil,
                "availability": productVariant.discontinued ? 'Discontinued' : AVAILABILITIES[productVariant.inventoryStatus],
                "url": `${siteUrl}${path}`,
              }
            }),
          })}
        </script>
      </Helmet>
    </>
  );
};

export default ProductSEO;
