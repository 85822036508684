import React, { useMemo, useState, useEffect } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const SizeSelector = props => {
  const {
    outOfStock,
    variantsAvailable,
    allProductSizesInOrder,
    possibleProductSizes,
    possibleProductVariants,
    selectedSize,
    setSelectedSize,
    selectedVariant,
  } = props;


  const [inventoryStatusByShopifyId, setInventoryStatusByShopifyId] = useState([])

  useEffect(() => {

    setInventoryStatusByShopifyId(possibleProductVariants.map(variant => {
      variant['available'] = variantsAvailable?.find(v => v.shopify_variant_id === variant.externalReferences[0].variant_id)?.available
      return variant
    })
  )

  }, [variantsAvailable])

  function isVariantAvailable (selectedVariant) {
    const available = inventoryStatusByShopifyId?.find(el => el._id === selectedVariant._id)?.available
    return available === undefined || available
  }
  function isSizeAvailable (size) {
    const available = inventoryStatusByShopifyId.find(el => el.productSize._id === size._id)?.available
    return available === undefined || available
  }

  // const inventoryStatusBySizeId = useMemo(
  //   () =>
  //     possibleProductVariants.reduce(
  //       (acc, productVariant) =>
  //         productVariant.productSize
  //           ? {
  //               ...acc,
  //               [productVariant.productSize._id]:
  //                 productVariant.inventoryStatus || "out-of-stock",
  //             }
  //           : acc,

  //       // Default all sizes to out-of-stock, then replace with actual inventoryStatus
  //       allProductSizesInOrder.reduce((acc, s) => ({
  //         ...acc,
  //         [s._id]: "out-of-stock",
  //       }), {})
  //     ),
  //   [allProductSizesInOrder, possibleProductVariants]
  // );

  return (
    <>
      <div className="text-sm mb-1">
        {outOfStock ? (
          <span className="text-depalmaRed uppercase">
            <FormattedMessage
              id="productView.sold-out"
              defaultMessage="Sold Out"
            />
          </span>
        ) : (
          selectedSize ? (
            <>
              <FormattedMessage
                id="productView.selected-size"
                description="Shows the currently selected size"
                defaultMessage="Size: {sizeAbbreviation}"
                values={{
                  sizeAbbreviation: selectedSize.abbreviation,
                }}
              />
              {!isVariantAvailable(selectedVariant) ? (
                <span className="averta-bold text-depalmaRed">
                  {' '}
                  <FormattedMessage
                    id={`productView.inventoryStatus.out-of-stock`}
                  />
                </span>
              ) : null}
            </>
          ) : (
            <FormattedMessage
              id="productView.select-size"
              description="Prompts visitor to select size"
              defaultMessage="Select size"
            />
          )
        )}
      </div>

      {allProductSizesInOrder.length > 1 ? (
        <div
          className={clsx(
            "flex items-baseline",
            allProductSizesInOrder.length >= 7
              ? "justify-between"
              : "justify-center"
          )}
        >
          {allProductSizesInOrder.map(size => (
            <label
              key={size._id}
              className={clsx(
                "h-10 w-10 text-sm flex items-center justify-center border border-depalmaBlue whitespace-nowrap mb-1",
                {
                  "bg-depalmaBlue text-depalmaWhite": selectedSize === size,
                  "bg-depalmaGray":
                    selectedSize !== size &&
                    !isSizeAvailable(size),
                  "bg-gray-500 text-depalmaWhite":
                    possibleProductSizes.indexOf(size) === -1,
                  "mx-1": allProductSizesInOrder.length < 7,
                },
              )}
            >
              <input
                className="hidden"
                name="size"
                type="radio"
                value={size.abbreviation}
                checked={size === selectedSize}
                onChange={() => setSelectedSize(size)}
              />
              {size.abbreviation}
            </label>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default SizeSelector;
