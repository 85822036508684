import React from "react";
import { FaInfo } from "react-icons/fa";

import { Swiper, SwiperSlide } from "@happy-rabbit/gatsby-sanity-cms/components";
import Tooltip from "../../components/Tooltip";
import ProductPhotoDetails from "./ProductPhotoDetails";
import { getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";
import {urlFor} from '../../utils/sanity'

function setImage(image) {
  // rect(left, top, width, height)
  const ih = image.asset.metadata.dimensions.height
  const iw = image.asset.metadata.dimensions.width
  const cr_l = parseInt(image.crop?.left*iw) || 1
  const cr_t = parseInt(image.crop?.top*ih) || 1
  const cr_r = parseInt(image.crop?.right*ih) || 1
  const cr_b = parseInt(image.crop?.bottom*ih) || 1
  const cr_w = iw - cr_l - cr_r || iw
  const cr_h = ih - cr_t - cr_b || ih

  const url = image.crop
    ? urlFor(image.asset.url).rect(cr_l, cr_t, cr_w, cr_h).url()
    : urlFor(image.asset.url).url()
  return url
}

const ProductSwiper = props => {
  const {
    productImages,
    slidesPerView = 1,
  } = props;

  const { lang } = useCMSContext();

  return (
    <div className="group h-full">
      <Swiper
        loop={true}
        slidesPerView={slidesPerView}
        navigation
        pagination={{ clickable: true }}
      >
        {productImages.map((productPhoto, i) => (
          <SwiperSlide key={i} className="h-auto">

            <div className="w-full relative">
            {/* <img
              className="m-auto w-auto"
              style={{ maxHeight: '85vh' }}
              src={setImage(productPhoto.image)}
              alt={getLocaleValue(productPhoto.altText, lang)}
            /> */}

              <img
                className="m-auto w-auto opacity-0"
                style={{ maxHeight: '85vh' }}
                src={setImage(productPhoto.image)}
                alt={getLocaleValue(productPhoto.altText, lang)} />
              <div
                className="bg-contain bg-no-repeat bg-center absolute top-0 left-0 w-full h-full"
                style={{
                  backgroundImage: `URL(${setImage(productPhoto.image)})`,
                  backgroundSize: productPhoto.image.hotspot ? 'cover' : 'contain',
                  backgroundPositionX: `${productPhoto.image.hotspot?.x*100}%`,
                  backgroundPositionY: `${productPhoto.image.hotspot?.y*100}%`
                }}/>
            </div>

            {/* <ResponsiveImage
              alt={getLocaleValue(productPhoto.altText, lang)}
              boxRatio={{xs: '3:4', lg: '1:1'}}
              image={productPhoto.image}
              noOfColsArr={[1,1,2,2,2]}
            /> */}
            <div className="md:opacity-0 group-hover:opacity-100 absolute bottom-0 left-0 pl-6 pb-8">
              <Tooltip>
                <FaInfo />
                <ProductPhotoDetails productPhoto={productPhoto} />
              </Tooltip>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductSwiper;
