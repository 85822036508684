import React from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";
import { urlFor } from "../../utils/sanity";
import { getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

const ColorwaySelector = props => {
  const {
    pageVariants,
    selectedProductColorway,
    showDiscontinued = false,
  } = props;

  const { lang } = useCMSContext();

  const renderColorwayButton = (
    { discontinued, pageConfig, productColorway },
    i
  ) => {
    const { _id, name, color, colorCategory } = productColorway;

    if (discontinued && !showDiscontinued) return null;

    const localeName = getLocaleValue(name, lang);

    return (
      <PageLink
        className="flex flex-initial w-12 h-12 items-center justify-center rounded-lg whitespace-nowrap mb-1 outline-none"
        key={_id}
        pageConfig={pageConfig}
      >
        <div
          title={getLocaleValue(name, lang)}
          className={clsx(
            "relative rounded-full w-12 h-12 flex items-center justify-center outline-none border border-transparent border-dashed",
            { "border-black": selectedProductColorway._id === _id }
          )}
        >
          {productColorway.image ? (
            <img
              className="w-10 h-10 rounded-full object-fit outline-none"
              src={urlFor(productColorway.image).width(40).height(40).url()}
              alt={localeName}
            />
          ) : (
            <div
              aria-label={localeName}
              className={clsx("rounded-full w-10 h-10 outline-none", {
                "border border-depalmaBlack":
                  colorCategory?.title?.en === "White",
              })}
              style={{ background: color?.color?.hex }}
            ></div>
          )}
          {discontinued ? (
            <div className="absolute border-b-2 w-full transform -rotate-45"></div>
          ) : null}
        </div>
      </PageLink>
    );
  };

  return (
    <>
      <div className="text-sm mb-1">
        <FormattedMessage
          id="productView.selected-color"
          description="Shows the currently selected colourway"
          defaultMessage="Colour: {colorName}"
          values={{
            colorName: getLocaleValue(selectedProductColorway?.name),
          }}
        />
      </div>
      <div className="flex items-baseline flex-wrap justify-center">
        {pageVariants.map(renderColorwayButton)}
      </div>
    </>
  );
};

export default ColorwaySelector;
