import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from "react-intl";
import { Button } from "@happy-rabbit/component-library";

import { useCartStateContext, variantLineItemFromCart } from "../../contexts/CartContext";
import { useDrawerStateContext } from '../../contexts/DrawerContext';
import { useCountryStateContext } from '../../contexts/CountryContext';


const ShoppingCartButton = props => {
  const {
    externalReference,
    product,
    selectedVariant,
  } = props;

  const { addVariantToCart, cart, updateLineItemInCart } = useCartStateContext();
  const { currencyCode } = useCountryStateContext();
  const { openRight } = useDrawerStateContext();
  const inventoryStatus = selectedVariant?.available === undefined || selectedVariant?.available ? 'in-stock' : 'out-of-stock';

  const inLineItem = useMemo(() => {
    if (externalReference?.variant_id) {
      const lineItem = variantLineItemFromCart(cart, externalReference.variant_id);
      return lineItem || null;
    }
    return null;
  }, [cart, externalReference]);

  const inCartQty = inLineItem ? inLineItem.quantity : 0;

  const handleAddToCartClick = useCallback(() => {
    const evtName = 'add';
    window.dataLayer = window.dataLayer || [];

    if (inLineItem) {
      try {
        window.dataLayer.push(function() { this.reset(); });
        window.dataLayer.push({
          event: `eec.${evtName}`,
          ecommerce: {
            currencyCode,
            [evtName]: {
              actionField: {
                list: 'Product View'
              },
              products: [{
                id: inLineItem.variant.sku,
                name: `${inLineItem.title} / ${inLineItem.variant.title}`,
                quantity: 1,
                dimension2: inLineItem.variant.selectedOptions[0]?.value || '',
                dimension3: inLineItem.variant.selectedOptions[1]?.value || '',
              }],
            },
          },
        });
      } catch(e) {
        console.log(e)
      }
      updateLineItemInCart(inLineItem.id, { quantity: inLineItem.quantity + 1 });

    } else {
      try {
        window.dataLayer.push(function() { this.reset(); });
        window.dataLayer.push({
          event: `eec.${evtName}`,
          ecommerce: {
            currencyCode,
            [evtName]: {
              actionField: {
                list: 'Product View',
              },
              products: [{
                id: selectedVariant.sku,
                name: `${[product.name, product.description?.en].filter(Boolean).join(' ')} / ${[selectedVariant.productColorway?.name?.en, selectedVariant.productSize?.abbreviation].filter(Boolean).join(' / ')}`,
                quantity: 1,
                dimension2: selectedVariant.productColorway?.name?.en || '',
                dimension3: selectedVariant.productSize?.abbreviation || '',
              }],
            },
          },
        });
      } catch(e) {
        console.log(e)
      }
      addVariantToCart(product, externalReference, 1);
      openRight();
    }
  }, [inLineItem, product, externalReference, selectedVariant, addVariantToCart, openRight, updateLineItemInCart, currencyCode]);

  return (
    <Button
      fullWidth={true}
      disabled={!externalReference || inventoryStatus === 'out-of-stock' || cart?.cartRequest}
      onClick={handleAddToCartClick}
      theme="black"
    >
      <FormattedMessage
        id={inCartQty === 0 ? "productView.add-to-cart" : "productView.in-cart"}
        defaultMessage={inCartQty === 0 ? "Add to cart" : "({quantity}) Add +1"}
        values={{
          quantity: inCartQty,
        }}
      />
    </Button>
  );
};

export default ShoppingCartButton;
