import React, { createRef, useCallback, useState } from "react";


const Tooltip = props => {
  const { children, tooltipText } = props;
  const [showing, setShowing] = useState(false);
  const timeoutRef = createRef(null);

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setShowing(true);
  }, [timeoutRef])

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = setTimeout(() => setShowing(false), 1500);
  }, [timeoutRef]);

  const [anchor, ...content] = children;

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showing ? (
        <div className="absolute left-0 bottom-8 text-sm whitespace-no-wrap shadow-lg border-depalmaBlack bg-depalmaWhite px-4 py-2 rounded transition-all duration-150">
          {content}
        </div>
      ) : null}
      {anchor}
    </div>
  );
}

export default Tooltip;
