import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { LocaleString } from "@happy-rabbit/gatsby-sanity-cms";

const FittingModel = props => {
  const { fittingModel } = props;
  const { height } = fittingModel;

  return (
    <div className="mt-2">
      <FormattedMessage
        id="productPhotoDetails.model-in-photo"
        defaultMessage="The model in the photo is {height} tall"
        values={{
          height,
        }}
      />
    </div>
  );
};

const TaggedProducts = props => {
  const { taggedProducts } = props;

  const renderTaggedProduct = useCallback((taggedProduct, i) => {
    const { product, productColorway, productSize } = taggedProduct;

    if (!product)
      return null;

    return (
      <div className="" key={i}>
        {product.name}
        {' '}
        <LocaleString value={product.description} />

        {productColorway ? (
          <>
            {' - '}
            <LocaleString value={productColorway.name} />
          </>
        ) : null}
        
        {productSize ? (
          <div className="">
            <FormattedMessage
              id="productPhotoDetails.size"
              defaultMessage="Size {abbreviation}"
              values={{
                abbreviation: productSize.abbreviation,
              }}
            />
            {productSize.abbreviation}
          </div>
        ) : null}
      </div>
    );
  })

  return (
    <div className="mt-2">
      <h4>
        <FormattedMessage
          id={taggedProducts.length === 1 ? "productPhotoDetails.one-product-in-photo" : "productPhotoDetails.multiple-products-in-photo"}
          defaultMessage="The product(s) in the photo are"
        />
      </h4>
      {taggedProducts.map(renderTaggedProduct)}
    </div>
  );
};

const ProductPhotoDetails = props => {
  const { productPhoto } = props;
  const { fittingModel, taggedProducts } = productPhoto;

  return (
    <div className="text-sm w-48">
      <h4 className="underline">
        <FormattedMessage
          id="productPhotoDetails.photo-details"
          defaultMessage="Photo Details"
        />
      </h4>

      {taggedProducts?.length ? <TaggedProducts taggedProducts={taggedProducts} /> : null}

      {fittingModel ? <FittingModel fittingModel={fittingModel} /> : null}
    </div>
  );
}

export default ProductPhotoDetails;
