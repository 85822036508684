import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LocaleString, LocaleText, PageSections } from "@happy-rabbit/gatsby-sanity-cms";

import Price from "../../components/Price";
import { useCountryStateContext } from "../../contexts/CountryContext";
import { useProductScope, useBestMatchProductImage } from "../../utils/productScope";
import { resolvePageSection } from "../PageSections";

import ColorwaySelector from "./ColorwaySelector";
import ProductSwiper from "./ProductSwiper";
import ShoppingCartButton from "./ShoppingCartButton";
import SizeSelector from "./SizeSelector";
import ProductSEO from "./ProductSEO";
import MoreInfoLink from "./MoreInfoLink";

import HeartBlack from "../../images/heart_black.svg";

import { getVariantStatus } from "../../utils/shopifyInventoryStatus";
import { useCartStateContext } from "../../contexts/CartContext";

const defaultPageSections = [
  {
    _type: 'pageSections.productDescriptionsAndCare',
    inclCare: true,
    inclDescriptions: true,
  }
];

const frontStudioFeatures = { category: 'S', subCategory: 'M', imageType: 'F' };

const ProductView = props => {
  const {
    productPage,
    productPageVariant,
  } = props;
  const { pageVariants } = productPage;

  const {
    discontinued,
    pageSections,
    product,
    productColorway,
  } = productPageVariant;

  const { currencyCode } = useCountryStateContext();

  const {
    // allOutOfStock,
    allProductSizesInOrder,
    extRefsByShop,
    possibleProductSizes,
    possibleProductVariants,
    scopedProductPhotos,
    selectedSize,
    setSelectedSize,
    selectedVariant,
  } = useProductScope(product, productColorway);

  const frontStudioImg = useBestMatchProductImage(scopedProductPhotos, frontStudioFeatures);
  const swiperImages = useMemo(() => scopedProductPhotos.filter(i => i !== frontStudioImg), [scopedProductPhotos, frontStudioImg]);

  const price = (selectedVariant?.price || {})[currencyCode?.toLowerCase()];


  useEffect(() => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(function() { this.reset(); });
      window.dataLayer.push({
        event: 'eec.detail',
        ecommerce: {
          currencyCode,
          detail: {
            actionField: {
              list: 'Product View',
            },
            products: [{
              id: product.code,
              name: [[product.name, product.description?.en].filter(Boolean).join(' '), productColorway.name?.en].filter(Boolean).join(' / '),
              dimension2: productColorway?.name?.en || '',
              dimension3: selectedSize?.abbreviation || '',
            }],
          },
        },
      });
    } catch(e) {
      console.log(e)
    }
  }, []);

  const [variantsAvailable, setVariantsAvailable] = useState([])
  const [outOfStock, setOutOfStock] = useState(false)
  const { client } = useCartStateContext();

  useEffect(() => {
    const fetchData = async () => {
      const product = productPageVariant.product.productVariants[0]
      const availability = await getVariantStatus(client, product)
      setVariantsAvailable(availability)
    }
    fetchData().catch(console.error)
  }, []);

  useEffect(() => {
    const isOutOfStock = variantsAvailable.length && variantsAvailable?.filter(i => i.available).length === 0
    setOutOfStock(isOutOfStock)
  }, [variantsAvailable])

  return (
    <>
      <ProductSEO
        image={scopedProductPhotos[0]?.image}
        possibleProductVariants={possibleProductVariants}
        product={product}
        productColorway={productColorway}
      />

      <div className="mx-auto">
        <div className="md:flex mb-8">
          <div className="flex-initial md:w-1/2">
            <ProductSwiper
              productImages={[...(frontStudioImg ? [frontStudioImg] : []), ...swiperImages]}
              slidesPerView={1}
            />
          </div>

          <div className="flex-initial md:w-1/2 lg:p-12 pt-10 flex flex-col items-center justify-start">
            <div className="w-80 text-center">
              <h1 className="text-2xl mb-1">
                <span className="averta-extrabold">{product.name}</span>
                <br/>
                <span><LocaleString value={product.description} /></span>
              </h1>

              <div className="text-xl averta-semibold mb-6">
                {price && !discontinued ? (price.compareToPrice ? (
                  <>
                    <span className="text-depalmaRed">
                      <Price
                        amount={price.amount}
                        currencyCode={price.currencyCode}
                        recalculateForVAT={true}
                      />
                    </span>{" "}
                    <span className="line-through ml-4">
                      <Price
                        amount={price.compareToPrice}
                        currencyCode={price.currencyCode}
                        recalculateForVAT={true}
                      />
                    </span>
                  </>
                ) : (
                  <Price {...price} recalculateForVAT={true} />
                )) : null}
              </div>

              <div className="max-w-sm text-sm mb-4">
                <LocaleText value={product.summary} />
              </div>

              <MoreInfoLink />

              <div className="mb-4">
                <ColorwaySelector
                  pageVariants={pageVariants}
                  selectedProductColorway={productColorway}
                  showDiscontinued={discontinued}
                />
              </div>

              <div className="mb-1">
                {discontinued ? (
                  <span className="text-depalmaRed uppercase">
                    <FormattedMessage
                      id="productView.discontinued"
                      defaultMessage="This product has been discontinued"
                    />
                  </span>
                ) : (
                  <SizeSelector
                    // allOutOfStock={allOutOfStock}
                    outOfStock={outOfStock}
                    variantsAvailable={variantsAvailable}
                    allProductSizesInOrder={allProductSizesInOrder}
                    possibleProductSizes={possibleProductSizes}
                    possibleProductVariants={possibleProductVariants}
                    selectedSize={selectedSize}
                    selectedVariant={selectedVariant}
                    setSelectedSize={setSelectedSize}
                  />
                )}
              </div>

              {!discontinued ? (
                <div className="mb-4 text-sm">
                  <ShoppingCartButton product={product} selectedVariant={selectedVariant} externalReference={extRefsByShop['depalma-workwear']} />
                </div>
              ) : null}

              <div className="flex-auto flex justify-center">
                <button className="focus:outline-none" onClick={() => {}}>
                  <img src={HeartBlack} alt={'Heart'} className="h-6" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {<PageSections pageSections={pageSections?.length ? pageSections : defaultPageSections} resolvePageSection={resolvePageSection} />}
      </div>
    </>
  );
};

export default ProductView;
